import { connect } from 'react-redux';
import React, {Component} from 'react';
import { closePopup } from '../actions/popup';
import { BASE_ZINDEX } from '../popup-factory';
import {Button, colors, AlertIcon, LabeledInput, PopupHeader, Row} from '@commonsku/styles';

class ConfirmationForDeletePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading : false,
            input:""
        };

    }
    handleClickYes() {
        const { redirect, method, params_arr, onClosePopup } = this.props;

        if(redirect) {
          this.setState({ loading: true }, function() {
            this.props.method(...this.props.params_arr);
          });
        }else{
          method(...params_arr);
          onClosePopup();
        }
      }
    render () {
        const {index, element, element_name, onClosePopup} = this.props;
        const message= "Are you sure?";
        return(
          <div id="replace-header-modal" className="reveal tiny" style={{display: 'block', height: 'auto', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
            <div className="row">
              <div className="small-12 columns">
                <PopupHeader>
                  <span className='title' id="modalTitle"><b>{message}</b></span>
                </PopupHeader>
              </div>
            </div>
            <div className="row column popup-content">
              <div style={{ background:'#FFF2F3', color:'#B4000C', marginBottom: '16px' }}>
                <div style={{ display: 'flex', padding: '16px' }}>
                  <div style={{ display: 'inline-block' }}>
                    <AlertIcon />
                  </div>
                  <div style={{ display: 'inline-block', paddingLeft: '16px' }}>
                    <span style={{ display: 'block' }}>
                      {element || element_name ? <>{'You are about to delete ' + element + " " + element_name + "."} </>: null}
                    </span>
                    <span style={{ display: 'block' }}>
                      <b>You cannot undo this action!</b>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <LabeledInput
                  label="Type DELETE here"
                  value={this.state.input}
                  onChange={(e) => {
                    const val = e.target.value;
                    this.setState({ input: val });
                  }}
                  placeholder="DELETE"
                  autoFocus={true}
                />
                <Row style={{gap:'16px'}}>
                  <Button variant="secondary" size="medium"
                    style={{width:'45%'}}
                    onClick={e => {
                      e.preventDefault();
                      onClosePopup();
                    }}
                  >Cancel</Button>
                  <Button variant="error" size="medium"
                    style={{width:'45%'}}
                    onClick={e => {
                      e.preventDefault();
                      this.handleClickYes();
                    }}
                    disabled={this.state.input.toLowerCase() == 'delete' ? false : true}
                  >Delete</Button>
                </Row>
              </div>
            </div>
          </div>
        );
    }

}
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => {
    return {
        onClosePopup : ()=> {
            dispatch(closePopup());
        }
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(ConfirmationForDeletePopup);